// custom typefaces
import 'typeface-oswald'
import '@openfonts/oswald_cyrillic'

import '@openfonts/fira-code_latin'
import '@openfonts/fira-code_cyrillic'

import 'typeface-oswald'
import '@openfonts/oswald_cyrillic'

import 'prismjs/themes/prism.css'
// import 'prismjs/themes/prism-solarizedlight.css'
import 'prismjs/plugins/line-numbers/prism-line-numbers.css'
import 'prismjs/plugins/command-line/prism-command-line.css'
